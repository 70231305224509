import React, {
  Component
} from 'react';
import ScrollableAnchor from 'react-scrollable-anchor'

import projectData from '../assets/projects.json'
import close from '../assets/close.svg'

import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';

import "../lib/video-react.css"; // import css

import './Projects.css';


import { Player } from 'video-react';

const projectImages = require.context('../../public/projects', true);

export default class absolute extends Component {
  handleClose = (id) => {
      if(this.player !== null && this.player !== undefined) this.player.pause()
    this.setState({
      openDetail: false
    })

    const that = this;
    setTimeout(() => {
      that.setState({
        currentProject: -1,
      })
    }, 1000);
  }

  handleClick = (id) => {
    if(this.player !== null && this.player !== undefined) this.player.pause()
    console.log(id);
    if (this.state.currentProject !== id) {
      let delay = 300;
      if (this.state.openDetail) delay = 0;

      this.setState({
        currentProject: id,
        openDetail: true
      })

      setTimeout(() => {
        window.location.href = '#project-detail';
      }, delay)

    }
  }

  constructor(props) {
    super(props);
    this.state = {
      currentProject: -1,
      openDetail: false
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.projectContent = this.projectContent.bind(this);
    this.getVideoPlayer = this.getVideoPlayer.bind(this);

    console.log(projectData);
  }

  getVideoPlayer = () => {
    console.log("Getting Player");

    const data = projectData[this.state.currentProject];
      return (
        <div><Player
        playsInline
        poster={projectImages(`./${data.images[0]}`)}
        src={projectImages("./"+data.video)}
        ref={(player) => { this.player = player }}
      /></div>
      )

  }

  projectContent = () => {
    let data = projectData[this.state.currentProject];
    const that = this;
    console.log(data)

    return (
      <div>

        <Slider
          onSlideChange={event => {if(this.player !== null && this.player !== undefined) this.player.pause()}}
          slideIndex={0}
          >


          {data.video !== null ? that.getVideoPlayer():(<div key={0} style={{backgroundImage: `url('${projectImages(`./${projectData[this.state.currentProject].images[0]}`)}')`}}></div>)}


          {data.images.filter((img,index)=>{
            if(index===0) return false
            return true
          }).map((img, index) => {
            return (<div key={index} style={{backgroundImage: `url('${projectImages(`./${img}`)}')`}}>
          </div>)
          }
            )}
        </Slider>


          <div className='container'>
            <img src={close} alt='close' className='close' onClick={this.handleClose}/>
        <h3>{data.title} <span className="year">{data.year}</span></h3>
        {data.subtitle}
        <br/>
        <br/>
        <div className="project-content">
          <p className="project-text" dangerouslySetInnerHTML={{__html:data.text}}></p>
          <p className="project-data">
            {data.data.map(function(item, i){
              return(
                <span key={i}>
                <b>{item.label+':'}</b><br/>
                <span dangerouslySetInnerHTML={{__html:item.text}}></span><br/>
                 <br/>
               </span>
              )
           })
          }
          </p>
        </div>
      </div>
      </div>
    )
  }

  render() {

    return (
      <div>
      <div className='Projects'>

        <div className='container'><h3>Projekte</h3>
          <div className='project-container'>
            {projectData.map((project,index)=>{
              return <div key={index} onClick={()=> {this.handleClick(index)}} className="project" style={{backgroundImage: `url('${projectImages(`./${project.thumb}`)}')`}}><span>{project.title}</span></div>
            })}


        </div>
      </div>
      </div>

      <div className={this.state.openDetail?'Detail open': 'Detail'}>


        <ScrollableAnchor  id={'project-detail'}>
          <div></div>
        </ScrollableAnchor>

        {this.state.currentProject >= 0 && this.state.currentProject < projectData.length &&
          this.projectContent()
        }


      </div>
      </div>

    );
  }
}
