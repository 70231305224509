import paper ,{view, Point,Path,Color} from 'paper';

let paths = [];
const dist = 10;
const numPaths = 5;
const numClusters = 3;

const maxClusters = 5;
const angles = [1 * 2 * Math.PI / 12, 5 * 2 * Math.PI / 12, 7 * 2 * Math.PI / 12, 11 * 2 * Math.PI / 12]

export default function Sketch() {

  window.onload = function() {
    paper.install(window);
    paper.setup('paper-canvas');

    for (let j = 0; j < numClusters; j++) {
      addRandomCluster();
    }

    view.onFrame = draw;

    view.onMouseUp = (event) => {
      console.log(event);

      if (paths.length / numPaths < maxClusters) addRandomCluster(event.point);
    }

    view.onResize = (event) => {
      for(const p of paths){
        console.log(p)
        p.killIt();
      }
      paths = []

    }
  }


   const draw = (event) => {
    var i = paths.length;
    while (i--) {
      paths[i].update();

      if (paths[i].shouldKill()) {
        paths.splice(i, 1);
      }
    }

    if (paths.length < numPaths * numClusters) {
      addRandomCluster();
    }
    // animation loop
  }

  // Most return null
  return null;
}


class PathLine {

  constructor(pos, angle, length, speed, killSpeed) {

    this.kill = false;

    let point2 = new Point(pos.x + Math.cos(angle) * length, pos.y + Math.sin(angle) * length);

    this.vel = new Point(pos.x - point2.x, pos.y - point2.y);

    let randomAhead = 0.1 * Math.random() - 0.05;

    pos.x += this.vel.x * randomAhead;
    pos.y += this.vel.y * randomAhead;

    point2.x += this.vel.x * randomAhead;
    point2.y += this.vel.y * randomAhead;

    this.vel = this.vel.divide(length);

    this.speed = speed;
    this.vel.x *= speed;
    this.vel.y *= speed;

    this.fadeIn = true;

    this.path = new Path();
    this.path.strokeColor = new Color(0, .667, .580392157);
    this.path.strokeColor.alpha = 0.0;



    this.path.add(pos);
    this.path.add(pos);

    this.lifeTime = 1;
    this.killSpeed = killSpeed;

    // this.head = new Path.Circle({
    //   center: this.path.segments[0].point,
    //   radius: 5,
    //   fillColor: 'red'
    // });

    this.bounds = new Path.Rectangle({
      x: 0,
      y: 0,
      width: view.size.width,
      height: view.size.height,
      //strokeColor: 'black'
    });
  }

  shouldKill() {
    return this.kill;
  }

  killIt(){
    this.kill = true;
    this.path.remove();
  }


  update() {
    if (this.fadeIn) {
      if (this.path.strokeColor.alpha < 1.0) this.path.strokeColor.alpha += 0.01;
      else this.fadeIn = false;
    } else {
      if (this.lifeTime > 0) this.lifeTime -= this.killSpeed;
      else this.killIt();
      this.path.strokeColor.alpha = this.lifeTime;

    }



    let dir = new Point(
      this.path.segments[this.path.segments.length - 2].point.x - this.path.segments[this.path.segments.length - 1].point.x,
      this.path.segments[this.path.segments.length - 2].point.y - this.path.segments[this.path.segments.length - 1].point.y
    )
    let mag = Math.sqrt(dir.x * dir.x + dir.y * dir.y);
    dir.x /= mag;
    dir.y /= mag;

    dir.x *= this.speed;
    dir.y *= this.speed;

    //this.path.segments[this.path.segments.length - 1].point.x += dir.x;
    //this.path.segments[this.path.segments.length - 1].point.y += dir.y;

    let intersection = this.path.getIntersections(this.bounds);
    if (this.path.segments.length > 2 && mag < 1) this.path.removeSegment(this.path.segments.length - 1);

    else if (intersection.length > 0 && this.path.segments.length < (2 + intersection.length)) {
      this.path.insert(1, intersection[0]._point);
    }


    var pre = this.path.segments[0].point;
    if (pre.x < this.bounds.x || pre.x > this.bounds.x + this.bounds.width) {
      this.vel.x *= -1;

    }

    if (pre.y < this.bounds.y || pre.y > this.bounds.y + this.bounds.height) {
      this.vel.y *= -1;
    }


    this.path.segments[0].point.x += this.vel.x;
    this.path.segments[0].point.y += this.vel.y;



    // this.head.position = this.path.segments[0].point;
    //
    // showIntersections(this.path, this.bounds);


  }

}

const addRandomCluster = (pos) => {

  const angle = angles[Math.floor(Math.random() * angles.length)]; //Math.PI * 2 * Math.random();
  const randomPoint = (pos === undefined ? new Point(Math.random() * view.size.width, Math.random() * view.size.height) : pos);
  const killSpeed = 0.0001 + Math.random() * 0.001;
  for (let i = 0; i < numPaths; i++) {

    paths.push(new PathLine(
      new Point(randomPoint.x + i * dist * Math.cos(angle + Math.PI * 0.5), randomPoint.y + i * dist * Math.sin(angle + Math.PI * 0.5)),
      angle,
      1000,
      0.5 * Math.random() + 0.75,
      killSpeed
    ));
  }
}

// const showIntersections = (path1, path2) => {
//   var intersections = path1.getIntersections(path2);
//   for (var i = 0; i < intersections.length; i++) {
//     new Path.Circle({
//       center: intersections[i].point,
//       radius: 5,
//       fillColor: '#009dec'
//     }).removeOnMove();
//   }
// }
