import React, {
  Component
} from 'react';
import './Claim.css';
import arrow from './../assets/arrow-down.svg'

export default class Claim extends Component {

  handleChange = () => {

    this.setState({
      last: this.state.current,
      current: Math.floor(Math.random() * 7)
    })


  }
  componentDidUpdate(){
    setTimeout(() => {
      const that = this;
      that.handleChange();
    }, Math.abs(this.state.current-this.state.last)*1000+3000)
  }

  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      last: 0
    }
    this.handleChange = this.handleChange.bind(this);

    const that = this;
    setTimeout(() => {
      that.handleChange();
    }, 3000)
  }

  render() {
    return (
      <div className='Claim'>
        <span className='greeting'>Hallo, ich bin</span>
          <div className="words" style={{left:(25-this.state.current*50)+'vw', transition: 'left '+Math.abs(this.state.current-this.state.last)+'s'}}>
            <h2 className={this.state.current===0?'highlighted':''}>Creative Coder</h2>
            <h2 className={this.state.current===1?'highlighted':''}>Geschäftsführer</h2>
            <h2 className={this.state.current===2?'highlighted':''}>Interaction Designer</h2>
            <h2 className={this.state.current===3?'highlighted':''}>Familienvater</h2>
            <h2 className={this.state.current===4?'highlighted':''}>Gesellschafter</h2>
            <h2 className={this.state.current===5?'highlighted':''}>Lehrbeauftragter</h2>
            <h2 className={this.state.current===6?'highlighted':''}>Gestalter</h2>
          </div>
          <img src={arrow} alt=""/>
        </div>
    );
  }
}
