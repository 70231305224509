import React from 'react';
import './App.css';
import {Helmet} from "react-helmet";
import Hero from './components/Hero.js'
import Claim from './components/Claim.js'
import About from './components/About.js'
import Projects from './components/Projects.js'

function App() {
  return (
    <div className="App">
      <Helmet>
                <meta charSet="utf-8" />
                <title>Stefan Kraiss Portfolio</title>
                  <link rel="apple-touch-icon" sizes="57x57" href="favicon.ico/apple-icon-57x57.png"/>
                  <link rel="apple-touch-icon" sizes="60x60" href="favicon.ico/apple-icon-60x60.png"/>
                  <link rel="apple-touch-icon" sizes="72x72" href="favicon.ico/apple-icon-72x72.png"/>
                  <link rel="apple-touch-icon" sizes="76x76" href="favicon.ico/apple-icon-76x76.png"/>
                  <link rel="apple-touch-icon" sizes="114x114" href="favicon.ico/apple-icon-114x114.png"/>
                  <link rel="apple-touch-icon" sizes="120x120" href="favicon.ico/apple-icon-120x120.png"/>
                  <link rel="apple-touch-icon" sizes="144x144" href="favicon.ico/apple-icon-144x144.png"/>
                  <link rel="apple-touch-icon" sizes="152x152" href="favicon.ico/apple-icon-152x152.png"/>
                  <link rel="apple-touch-icon" sizes="180x180" href="favicon.ico/apple-icon-180x180.png"/>
                  <link rel="icon" type="image/png" sizes="192x192"  href="favicon.ico/android-icon-192x192.png"/>
                  <link rel="icon" type="image/png" sizes="32x32" href="favicon.ico/favicon-32x32.png"/>
                  <link rel="icon" type="image/png" sizes="96x96" href="favicon.ico/favicon-96x96.png"/>
                  <link rel="icon" type="image/png" sizes="16x16" href="favicon.ico/favicon-16x16.png"/>
                  <link rel="manifest" href="favicon.ico/manifest.json"/>
                  <meta name="msapplication-TileColor" content="#ffffff"/>
                  <meta name="msapplication-TileImage" content="favicon.ico/ms-icon-144x144.png"/>
                  <meta name="theme-color" content="#ffffff"/>
            </Helmet>
      <Hero/>
      <Claim/>
      <About/>
      <Projects/>
      <div className="Footer"><div className="container">
        © {new Date().getYear()+1900} Stefan Kraiss<br/>
      hello<code>@</code>stefan-kraiss.de<br/>
      Besuchen Sie <a href="http://17k.de">17K GmbH</a> für weitere Projekte
      </div></div>
    </div>
  );
}

export default App;
