import React, {
  Component
} from 'react';
import './About.css';

export default class absolute extends Component {


  render() {
    return (
      <div className='About'>
        <div className='portrait'></div>
        <div className='container'>
          <h3>Über Mich</h3>
          <div className='text'>

            Ich sehe mich als sehr strukturierten und analytischen Gestalter. Durch meine starke Orientierung in Richtung des Creative Coding mit breiten technischen Kenntnissen habe ich gelernt, Gestaltungsprobleme genau zu beleuchten und sowohl von der kreativen als auch von der technischen Seite betrachten zu können. Somit kann ich als Schnittstelle zwischen Kreation und Umsetzung fungieren. In beiden Bereichen fühle ich mich zuhause.<br/> <br/>
          Meine Ausbildung im Studium der Kommunikations­gestaltung habe ich früh in Richtung der digitalen Medien gelenkt. In meinem Masterstudium „Media- & Interaction‑Design“ habe ich dies nochmals intensiv vertieft. Ich bezeichne mich als Creative Coder da das programmierte Entwerfen mein Hauptwerkzeug geworden ist. In diesem Sinne entwickle ich komplexe Systeme, die Informationen vermitteln, dynamische und interaktive Inhalte vermitteln oder neue grafische Bildwelten erzeugen. <br/> <br/>
  Ich entwickle vielfältige digitale Produkte vom Konzept bis zur Umsetzung. Von ersten User-Stories zu Designsystemen und User-Interfaces für die unterschiedlichsten Medien über erste Prototypen bis hin zum final programmierten Produkt.
  <br/> <br/>
  Als Geschäftsführer und Gesellschafter der 17K GmbH
  habe ich mit meinen Partnern ein erfolgreiches Design-Startup aufgebaut. Kommunikation mit Kunden und Projektpartnern, Projektakquise und Verhandlungen sowie die Führung von internen und externen Mitarbeitern sind hierbei ein fester Teil meines Arbeitsalltags.
  <br/> <br/>
  Darüber hinaus ist die Vermittlung von Wissen auch ein
  sehr wichtiger Teil von mir. Nicht nur ist die Informations-
  Visualisierung eine meiner gestalterischen Leiden­schaften. Auch die Weitergabe von Wissen durch Workshops und vor allem Lehraufträgen an Hochschulen ist ein großer Faktor in meinem Dasein als Gestalter.

          </div>

        </div>

  </div>
    );
  }
}
