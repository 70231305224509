import React, {Component} from 'react';
import './Hero.css';
import Sketch from './Sketch.js';

export default class Hero extends Component {

render() {
    return(
        <div className='Hero'>
            <canvas id='paper-canvas' resize='true' />
            <Sketch />
            <h1><span>Stefan Kraiss{'\n'}</span><br/><span className='light'>Portfolio</span></h1>
        </div>
    );
  }
}
